<template>
  <section v-if="!loading">
    <TopNavigation :title="course.title"/>
    <TabCourse v-if="value==1" :course="course"/>
    <v-sheet v-if="value==0" class="">
      <h4 class="roboto secondary-3--text ma-5">Announcements</h4>
      <v-list two-line class="mt-n3">
      <v-divider/>
        <section v-for="(item, i) in course.announcements" :key="i">
          <v-list-item >
            <v-list-item-content>
              <v-list-item-title class="roboto f14">
                <v-avatar class="mr-2">
                  <v-img contain :src="item.user.image?item.user.image.url :require('../../../assets/default-photo.png')"
                    v-on:error="require('../../../assets/default-photo.png')"/>
                </v-avatar>
                {{`${item.user.first_name} ${item.user.last_name}`}}
              </v-list-item-title>
              <div class="mt-2 roboto f13 fw400" v-html="item.content ? $dompurifier(item.content) : ''"/>
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
        </section>
      </v-list>
    </v-sheet>
    <v-bottom-navigation 
      color="primary" 
      v-model="value" 
      height="50"
      app
      background-color="#FFF" 
      class="custom-border roboto"
    >
      <v-btn v-for="(item, i) in tabs" :key="i">
        <v-icon size="20" v-if="item == 'Announcement'">mdi-bullhorn-outline</v-icon> 
        <v-icon size="20" v-if="item == 'Details'">mdi-book-open</v-icon> 
        <v-icon size="20" v-if="item == 'Modules'">mdi-file-edit-outline</v-icon> 
      </v-btn>
    </v-bottom-navigation>
  </section>
  <circular v-else class="my-10"/>
</template>

<script>
import {
  ref,
  onMounted,
  computed
} from '@vue/composition-api'

import TopNavigation from '../../../components/mobile/TopNavigation.vue'
import TabCourse from '../../../components/mobile/user/TabCourse.vue';

export default {
  components: {
    TopNavigation,
    TabCourse
  },
  setup(props, context) {

    const route = context.root.$route;
    const store = context.root.$store;
    const loading = ref(true)
    const value = ref(1)
    const tabs = ref([
      'Announcement',
      'Details',
      'Modules',
    ])
    const asset_path = `${process.env.VUE_APP_ASSET_PATH}`

    const course = computed(() => store.state.usr.object)

    onMounted(() => {
      store.dispatch('usr/showCourseAction', route.params.uuid)
      .then(_ => {
        loading.value = false
      })
    })

    return {
      course,
      loading,
      value,
      tabs,
      asset_path
    }
  }
}
</script>
