<template>
  <v-app-bar app flat class="custom-border" color="#FFF">
    <v-btn icon @click="$router.back()">
      <v-icon size="20">mdi-arrow-left</v-icon>
    </v-btn>
    <v-app-bar-title class="poppins f15 fw600">
      {{title}}
    </v-app-bar-title>
  </v-app-bar>
</template>

<script>
import {
  ref,
} from '@vue/composition-api'

export default {
  props: ['title']
}
</script>