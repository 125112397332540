<template>
  <v-sheet class="ma-5">
    <v-sheet outlined rounded class=" pa-5">
      <v-img contain max-height="200" 
        :src="course.image?course.image.url:require('../../../assets/default-photo.png')"
        v-on:error="require('../../../assets/default-photo.png')"></v-img>
    </v-sheet>
    <div class="mt-2">
      <div class="d-flex align-center justify-space-between">
        <h4 class="poppins secondary-3--text fw600">
          {{course.title}}
        </h4>
        <v-btn class="" icon>
          <v-icon size="20">mdi-star-outline</v-icon>
        </v-btn>
      </div>
      
      <div class="f14 secondary-2--text">
        {{`${course.uploader.first_name} ${course.uploader.last_name}`}}
      </div>
      <div class="f10 secondary-2--text">
        Created: {{`${$dateFormat.mmDDyy(course.created_at)}`}}
      </div>
    </div>
  </v-sheet>
</template>

<script>
import {
  toRefs
} from '@vue/composition-api'
export default {
  props: {
    course: Object
  },
  setup(props) {
    const asset_path = `${process.env.VUE_APP_ASSET_PATH}`

    return {
      asset_path
    }
  }
}
</script>
